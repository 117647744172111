import { reads } from 'macro-decorators';
import { waitForProperty } from 'ember-concurrency';
import Service, { service } from '@ember/service';

export default class DevicesAdapterService extends Service {
  @service('twilio.room') twilioRoom;
  @service('chime.meeting-manager') meetingManager;
  @service('chime.local-audio-video') localAudioVideo;
  @service errorHandling;
  @service mediaDevices;
  @service mixpanel;
  @service persistentProperties;
  @service session;

  @reads('session.roomModel.featureThChime') featureThChime;
  @reads('persistentProperties.isVideoPublished') isVideoPublished;
  @reads('persistentProperties.isAudioPublished') isAudioPublished;
  @reads('persistentProperties.selectedVideoQuality') selectedVideoQuality;

  async handleVideoDeviceChange(deviceId) {
    this.persistentProperties.setProp('selectedVideoDeviceId', deviceId);

    if (this.featureThChime) {
      await this.localAudioVideo.changeVideoInputDevice(deviceId);
    } else {
      this.twilioRoom.handleVideoChange();
    }
  }

  async handleAudioInputDeviceChange(deviceId) {
    this.persistentProperties.setProp('selectedAudioInputDeviceId', deviceId);

    if (this.featureThChime) {
      await this.localAudioVideo.changeAudioInputDevice(deviceId);
    } else {
      await waitForProperty(this.twilioRoom, 'audioTrack', Boolean);
      this.twilioRoom.handleAudioInputChange();
    }
  }

  async handleAudioOutputDeviceChange(deviceId) {
    this.persistentProperties.setProp('selectedAudioOutputDeviceId', deviceId);

    if (this.featureThChime) {
      await this.localAudioVideo.changeAudioOutputDevice(deviceId);
    } else {
      this.twilioRoom.handleAudioOutputChange();
    }
  }

  async handleNoiseCancellationChange() {
    try {
      this.persistentProperties.setProp(
        'noiseCancellationEnabled',
        !this.persistentProperties.noiseCancellationEnabled
      );

      return this.featureThChime
        ? await this.localAudioVideo.handleAudioInputChange()
        : await this.twilioRoom.toggleNoiseCancellationTask.perform();
    } catch (err) {
      this.persistentProperties.setProp('noiseCancellationEnabled', false);
      this.mixpanel.track('noise cancellation', { status: 'error' });
      this.errorHandling.notifyError(err);
    }
  }

  handleVideoQualityChange(videoQuality) {
    this.persistentProperties.setProp('selectedVideoQuality', videoQuality);

    if (this.featureThChime) {
      this.localAudioVideo.handleVideoInputChange();
    } else {
      this.twilioRoom.handleVideoQualityChange(videoQuality);
    }

    this.mixpanel.track('video quality', { type: videoQuality });
  }

  get videoDevices() {
    return this.featureThChime
      ? this.localAudioVideo.videoInputDevices
      : this.mediaDevices.videoDevices;
  }

  get audioInputDevices() {
    return this.featureThChime
      ? this.localAudioVideo.audioInputDevices
      : this.mediaDevices.audioInputDevices;
  }

  get audioOutputDevices() {
    return this.featureThChime
      ? this.localAudioVideo.audioOutputDevices
      : this.mediaDevices.audioOutputDevices;
  }

  get selectedVideoDeviceId() {
    return this.featureThChime
      ? this.localAudioVideo.selectedVideoInputDevice
      : this.persistentProperties.selectedVideoDeviceId;
  }

  get selectedAudioInputDeviceId() {
    return this.featureThChime
      ? this.localAudioVideo.selectedAudioInputDevice
      : this.persistentProperties.selectedAudioInputDeviceId;
  }

  get selectedAudioOutputDeviceId() {
    return this.featureThChime
      ? this.localAudioVideo.selectedAudioOutputDevice
      : this.persistentProperties.selectedAudioOutputDeviceId;
  }
}
