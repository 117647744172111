export const PERMISSION_DENIED_ERROR = 'PermissionDeniedError';
export const OVERCONSTRAINED_ERROR = 'OverconstrainedError';
export const EMPTY_DEVICES_LIST_ERROR = 'Required devices list is empty';
export const DEVICES_TIMEOUT_ERROR = 'DevicesTimeoutError';
export const GET_USER_MEDIA_ERROR = 'GetUserMediaError';
export const NOT_ALLOWED_ERROR = 'NotAllowedError';
export const CUSTOM_ERROR_CODES = {
  validationFailed: 100,
  videoDisabled: 101,
  roomNotFound: 102,
  deprecatedVersion: 104,
  participantsLimitReached: 106,
};
