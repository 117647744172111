import { confirmation, destruction, information } from 'frontend/utils/modals';
import { createElement } from 'ember-simplepractice/utils/toasts';

export const appointmentEnded = isHostLostConnection =>
  confirmation({
    title: isHostLostConnection ? 'Your clinician lost connection' : 'Appointment ended',
    text: isHostLostConnection
      ? 'You can rejoin the appointment and wait for your clinician.'
      : 'Your clinician left and ended the appointment.',
    confirmButtonText: isHostLostConnection ? 'Rejoin appointment' : 'OK',
    allowOutsideClick: false,
    showCancelButton: false,
  });

export const ensureAppointmentEnding = () =>
  destruction({
    title: 'End appointment?',
    text: 'This will end the video appointment for all participants.',
    input: 'checkbox',
    inputValue: 0,
    inputPlaceholder: "Don't ask again",
    customClass: {
      input: 'show-again-checkbox',
    },
    cancelButtonText: 'Cancel',
    confirmButtonText: 'End',
  });

export const ensureStopWhiteboardSharing = () =>
  destruction({
    title: 'Are you sure you want to stop sharing?',
    text: 'When you stop sharing, any unsaved changes will be lost.',
    cancelButtonText: 'Cancel',
    confirmButtonText: 'Stop sharing',
  });

export const cannotStartSharing = () =>
  information({
    title: 'You can not start sharing',
    text: 'Other participant started sharing before you',
    confirmButtonText: 'OK',
  });

export const cannotChangeSettings = () =>
  information({
    title: "We can't change your settings",
    text: 'Please, check your internet connection and try again.',
    confirmButtonText: 'Try again',
  });

export const recordingConsent = () =>
  confirmation({
    title: 'Allow recording of this appointment?',
    html: `Your clinician uses HIPAA-compliant artificial intelligence (AI)
      to record and transcribe appointment audio. That way, they can focus on your time
      together and efficiently create an accurate record of your care.
      <br><br>
      The recording and transcription are permanently deleted within 2 weeks.
      In addition to being used for your care, the SimplePractice team may
      occasionally review de-identified transcripts to improve the feature.`,
    confirmButtonText: 'Allow recording',
    cancelButtonText: 'Don’t allow',
    allowOutsideClick: false,
    showCloseButton: false,
  });

export const suggestAnotherDevice = ({ deviceType, selectedDeviceLabel, suggestedDeviceLabel }) =>
  confirmation({
    title: `Change ${deviceType} device`,
    html: createElement('p', {
      innerHTML:
        `We can't access "${selectedDeviceLabel}".<br><br> It may be in use by another application or browser tab. Try closing ` +
        'any apps or tabs that are using the microphone or camera, and then refresh this page.<br><br>' +
        `If that doesn't resolve the issue, click "Change device" to switch to "${suggestedDeviceLabel}."<br>`,
    }),
    allowOutsideClick: false,
    confirmButtonText: 'Change device',
    cancelButtonText: 'Refresh',
    showCloseButton: false,
  });

export const requestAudioDeviceAccess = () =>
  confirmation({
    title: 'We need your permission',
    text: 'To continue, please allow access to your Audio device.',
    confirmButtonText: 'Allow',
    showCloseButton: false,
    showCancelButton: false,
    allowOutsideClick: false,
  });
