import { DEVICE_KINDS, SCREEN_CONSTRAINTS } from 'frontend/constants';
import { OVERCONSTRAINED_ERROR } from 'frontend/constants/errors';
import { isSafari } from 'frontend/utils/detect-browser';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Service from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class MediaDevicesService extends Service {
  @tracked devices = [];

  get isRequestingPermissions() {
    return this.triggerDeviceLabelsTask.isRunning;
  }

  get videoDevices() {
    return this.devices.filter(device => device.kind === DEVICE_KINDS.videoInput);
  }

  get audioInputDevices() {
    return this.devices.filter(device => device.kind === DEVICE_KINDS.audioInput);
  }

  get audioOutputDevices() {
    return this.devices.filter(device => device.kind === DEVICE_KINDS.audioOutput);
  }

  getDevices = async () => {
    if (this.isDestroying || this.isDestroyed) {
      return;
    }
    this.devices = await this.enumerateDevices();
  };

  findById(deviceId) {
    return this.devices.find(device => device.deviceId === deviceId);
  }

  trackDevices() {
    // Backward compatibility with old Safari versions
    if (navigator.mediaDevices.addEventListener) {
      navigator.mediaDevices.addEventListener('devicechange', this.getDevices);
    } else if (navigator.mediaDevices.ondevicechange) {
      navigator.mediaDevices.ondevicechange = this.getDevices;
    }
    return this.getDevices();
  }

  // workaround for stubbing in tests
  enumerateDevices() {
    return navigator.mediaDevices.enumerateDevices();
  }

  async getUserMedia(constraints = { audio: true, video: true }) {
    return await navigator.mediaDevices.getUserMedia(constraints);
  }

  getDisplayMedia() {
    let constraint = this.screenConstraint;
    if (navigator.mediaDevices.getDisplayMedia) {
      return navigator.mediaDevices.getDisplayMedia(constraint);
    }

    return navigator.getDisplayMedia(constraint);
  }

  get screenConstraint() {
    return isSafari() ? SCREEN_CONSTRAINTS.video : SCREEN_CONSTRAINTS;
  }

  async takeDevicesControl() {
    await this.triggerDeviceLabelsTask.perform({ audio: true, video: true });
    let shouldAskForMediaPermissions =
      this.videoDevices.every(this.isDeviceUnavailable) ||
      this.audioInputDevices.every(this.isDeviceUnavailable) ||
      this.audioOutputDevices.every(this.isDeviceUnavailable);

    if (!shouldAskForMediaPermissions) return;
    let mediaStream = await this.getUserMedia();
    mediaStream.getTracks().forEach(track => track.stop());
  }

  isDeviceUnavailable(device) {
    return !(device.deviceId && device.label);
  }

  triggerDeviceLabels(videoConstraints) {
    return this.triggerDeviceLabelsTask.perform(videoConstraints);
  }

  cancelDeviceLabelsTrigger() {
    this.triggerDeviceLabelsTask.cancelAll();
  }

  triggerDeviceLabelsTask = task(async videoConstraints => {
    let { width, height, frameRate } = videoConstraints;
    let mediaStream;

    try {
      mediaStream = await this.getUserMedia({
        audio: true,
        video: { width, height, frameRate },
      });
    } catch (e) {
      if (e.name !== OVERCONSTRAINED_ERROR) throw e;
      mediaStream = await this.getUserMedia({ audio: true, video: true });
    }

    return mediaStream;
  });

  async getPermissionDenied() {
    if (!navigator.permissions) return false;

    let permissions = ['camera', 'microphone'];

    try {
      for (const name of permissions) {
        let status = await navigator.permissions.query({ name });

        if (status.state === 'denied') return true;
      }
    } catch {
      // noop
    }

    return false;
  }
}
